import React from "react"
import CollapsingSegment from "../../../common/collapsingSegment"
import { Grid, GridColumn, GridRow } from "semantic-ui-react"
import ControlDateField from "../../../fields/ControlDateField"
import { useTranslation } from "react-i18next"
import { Control, FieldValues } from "react-hook-form"
import ControlCheckbox from "../../../fields/ControlCheckbox"
import ControlInput from "../../../fields/ControlInput"

interface ICloseSectionProps<T extends FieldValues = any> {
    control: Control<T>
    isSaving: boolean
    title: string
    isNew: boolean
    isAdmin: boolean
}

const CloseSection = ({ control, isSaving, title, isNew, isAdmin }: ICloseSectionProps) => {
    const { t } = useTranslation()
    return (
        <CollapsingSegment heading={title}>
            <Grid stackable>
                {isAdmin && <GridRow columns={1}>
                    <GridColumn >
                        <ControlCheckbox control={control} disabled={isSaving} label={t('evaluationRequired')} name='useReview' />
                    </GridColumn>
                </GridRow>}
                {!isNew && <GridRow columns={1}>
                    <GridColumn >

                        <ControlCheckbox control={control} disabled={isSaving} label={t('feedbackReporter')} name='feedback' />
                    </GridColumn>
                </GridRow>}
                <GridRow columns={2}>
                    <GridColumn >
                        <ControlInput
                            control={control}
                            disabled={isSaving}
                            label={t('endedBy')}
                            name='endedBy' />
                    </GridColumn>
                    <GridColumn>
                        <ControlDateField
                            name="endedDate"
                            label={t('endedDate')}
                            control={control}
                            disabled={isSaving} />
                    </GridColumn>
                </GridRow>

            </Grid>
        </CollapsingSegment>
    )
}
export default CloseSection