export enum ModuleStatus {
    Active = 1,
    Evaluate = 2,
    Closed = 4
}

export enum IssueStatus {
    Active = 1,
    Evaluate = 2,
    Closed = 3
}

export enum AbsenceTimeType {
    AbsenceNone = 0,
    AbsenceShort = 1,
    AbsenceMid = 2,
    AbsenceLonger = 3,
    AbsenceDeath = 4,
}

export enum EmploymentType {
    FullTimeEmployee = 0,
    PartEmployee = 1,
    OwnEmployee = 2,
    PoliticalEmployee = 3,
    Student = 4,
    HiredStaff = 5
}