import {
    ApplicationState,
    User,
    Department,
    Position,
    Location,
    Company,
    Error,
    Supplier,
} from '../state/initial'
import { ProfileWithRoles } from '../auth/roles'

export type Action =
    | { type: 'departmentsLoaded'; departments: Department[] }
    | { type: 'positionsLoaded'; companyPositions: Position[]; concernPositions: Position[] }
    | { type: 'locationsLoaded'; locations: Location[] }
    | {
        type: 'companyLoaded'
        company: Company
        concern: Company | undefined
        hasSubCompanies: boolean
    }
    | { type: 'companyLogoPathLoaded'; path: string }
    | { type: 'usersLoaded'; users: User[] }
    | { type: 'gotError'; error: Error }
    | { type: 'clearError'; error: Error }
    | { type: 'userModulesLoaded'; modules: number[] }
    | { type: 'userProfileLoaded'; profile: ProfileWithRoles }
    | { type: 'suppliersLoaded'; suppliers: Supplier[] }

export const mainReducer = (prevState: ApplicationState, action: Action): ApplicationState => {
    switch (action.type) {
        case 'departmentsLoaded':
            return {
                ...prevState,
                departments: action.departments,
            }
        case 'positionsLoaded':
            return {
                ...prevState,
                companyPositions: action.companyPositions,
                concernPositions: action.concernPositions,
            }
        case 'locationsLoaded':
            return {
                ...prevState,
                locations: action.locations,
            }
        case 'companyLoaded':
            return {
                ...prevState,
                company: action.company,
                concern: action.concern,
                hasSubCompanies: action.hasSubCompanies,
            }
        case 'usersLoaded':
            return {
                ...prevState,
                users: action.users.filter(u => !u.virtual),
                allUsers: action.users,
            }
        case 'gotError':
            return {
                ...prevState,
                errors: [action.error, ...prevState.errors],
            }
        case 'clearError':
            return {
                ...prevState,
                errors: prevState.errors.filter(e => e !== action.error),
            }
        case 'userModulesLoaded':
            return {
                ...prevState,
                modules: action.modules,
            }
        case 'userProfileLoaded':
            return {
                ...prevState,
                userProfile: action.profile,
            }
        case 'companyLogoPathLoaded':
            return {
                ...prevState,
                companyLogoPath: action.path,
            }
        case 'suppliersLoaded':
            return {
                ...prevState,
                suppliers: action.suppliers,
            }
        default:
            return prevState
    }
}
