import { Profile } from 'oidc-client'

export const ProjectsAdminRole = 'proA'
export const SurveysAdminRole = 'kunA'
export const IssuesAdminRole = 'avvA'
export const DocumentsAdminRole = 'dokA'
export const GoalsAdminRole = 'malA'
export const RisksAdminRole = 'riskA'
export const ViqmaAdminRole = 'Viqma'
export const SystemAdminRole = 'SA'
export const ImprovementsAdmin = "forA";

export enum AdminRoles {
    ProjectsAdminRole = 'proA',
    SurveysAdminRole = 'kunA',
    IssuesAdminRole = 'avvA',
    DocumentsAdminRole = 'dokA',
    GoalsAdminRole = 'malA',
    RisksAdminRole = 'riskA',
    ViqmaAdminRole = 'Viqma',
    SystemAdminRole = 'SA',
    ImprovementsAdmin = "forA"
}

export interface ProfileWithRoles extends Profile {
    role: string[]
    companyId: string
}
// TODO: change roleName to enum to prevent magic strings
export const isInRole = (profile: ProfileWithRoles | undefined, roleName: string) =>
    (profile?.role?.indexOf(roleName) ?? -1) !== -1
