import React from 'react'
import { SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic'
import { isInPageRenderMode } from '../../actions/util'
import { MODULES } from '../../auth/modules'
import { buildLinkToFile } from '../../hooks/files'
import { FileIcon } from '../common/files'
import UserNameDisplay from '../common/UserNameDisplay'
import DocumentResponsiblePositionCell from './documentResponsiblePositionCell'
import { ModuleData } from './pane'
import { RowInfo, RowInfoBasic } from './rowInfo'
import DocumentName from '../modules/docs/common/documentName'

const webFormsHostedFileOverrideCellInnerContent = {
    name: (row: any, name: string) => (
        <a href={row.url?.replace('~', '').replace('..', '')} target="_blank" rel="noreferrer">
            <FileIcon file={row} color="black" /> {name}
        </a>
    ),
}

export const getSearchPageRowInfo =
    (setModuleData?: React.Dispatch<React.SetStateAction<ModuleData>>) =>
        (subTitleKey: string, moduleId: number, row: any): RowInfoBasic => {
            const rowInfo: RowInfo = {
                [MODULES.DOCS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/docs/${row.linkId}`
                        : `/alla/dokument.aspx#/${row.linkId}`,
                    displayRowIfNoUserSet: true,
                    overrides: {
                        routines: {
                            linkProperty: 'topic',
                            hideColumnIfNoValuesAreSet: false,
                            hiddenColumns: ['isPublished', "approvedById"],
                            overrideCellInnerContent: {
                                topic: row => (
                                    <DocumentName isPublished={row.isPublished} name={row.topic} />
                                ),
                                approvedBy: (_, value) => <UserNameDisplay user={value} />,
                                responsiblePositionId: (row, value) => (
                                    <DocumentResponsiblePositionCell
                                        documentId={row.linkId}
                                        currentPositions={value}
                                        onUpdate={newResponsiblePositionIds => {
                                            setModuleData?.(prevState => {
                                                const dataItem = prevState[MODULES.DOCS].find(
                                                    x => x.subTitleKey === 'routines',
                                                )

                                                if (dataItem) {
                                                    const dataRow = dataItem.rows.find(
                                                        x => x.linkId === row.linkId,
                                                    )
                                                    if (dataRow)
                                                        (dataRow.responsiblePositionId as unknown as number[]) =
                                                            newResponsiblePositionIds
                                                }

                                                return { ...prevState }
                                            })
                                        }}
                                    />
                                ),
                            },
                            overrideHeaderString: {
                                responsiblePositionId: 'responsible',
                            },
                        },
                        sharedRoutines: {
                            linkProperty: 'topic',
                            hideColumnIfNoValuesAreSet: false,
                            hiddenColumns: ['isPublished'],
                            overrideCellInnerContent: {
                                approvedBy: (_, value) => <UserNameDisplay user={value} />,
                            },
                        },
                        uploadedFiles: {
                            linkProperty: 'routine',
                            hiddenColumns: ['fileId', 'downloadToken'],
                            overrideTitle: 'documentsUploadedFiles',
                            overrideCellInnerContent: {
                                name: (row, name) => (
                                    <a
                                        href={buildLinkToFile(
                                            'Documents',
                                            row.fileId,
                                            row.downloadToken,
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <FileIcon file={row} color="black" /> {name}
                                    </a>
                                ),
                            },
                        },
                        sharedUploadedFiles: {
                            linkProperty: 'routine',
                            hiddenColumns: ['fileId', 'downloadToken'],
                            overrideTitle: 'sharedDocumentsUploadedFiles',
                            overrideCellInnerContent: {
                                name: (row, name) => (
                                    <a
                                        href={buildLinkToFile(
                                            'Documents',
                                            row.fileId,
                                            row.downloadToken,
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <FileIcon file={row} color="black" /> {name}
                                    </a>
                                ),
                            },
                        },
                    },
                },
                [MODULES.SKILLS]: {
                    displayRowIfNoUserSet: true,
                    hiddenColumns: ['url'],
                    userIdField: ['userId'],
                    linkUrl: '/kompetens/befattningar.aspx',
                    bypassRouter: true,
                    linkProperty: 'user',
                    overrideCellInnerContent: webFormsHostedFileOverrideCellInnerContent,
                    hideRowIfUserIsInactive: true,
                    overrideTitle: 'skillsUploadedFiles',
                },
                [MODULES.ISSUES]: {
                    displayRowIfNoUserSet: true,
                    hiddenColumns: ['url', 'fileId'],
                    bypassRouter: true,
                    linkProperty: 'issue',
                    overrideCellInnerContent: webFormsHostedFileOverrideCellInnerContent,
                    overrides: {
                        uploadedFiles: {
                            linkUrl: isInPageRenderMode()
                                ? `/issues/detail/${row.linkId}`
                                : `/avvikelse/avv.aspx#/detail/${row.linkId}`,
                            overrideTitle: 'issueUploadedFiles',
                        },
                        certexUploadedFiles: {
                            linkUrl: `/avvikelseCertex/cerAvv.aspx?id=${row.linkId}`,
                        },
                        accidentUploadedFiles: {
                            linkUrl: isInPageRenderMode()
                                ? `/issues/accidents/${row.linkId}`
                                : `/avvikelse/avv.aspx#/accidents/${row.linkId}`,
                            overrideHeaderString: {
                                issue: 'accidentIncidentRisk',
                            },
                        },
                    },
                },
                [MODULES.PROJECTS]: {
                    displayRowIfNoUserSet: true,
                    hiddenColumns: ['url', 'fileId'],
                    linkProperty: 'project',
                    overrideCellInnerContent: webFormsHostedFileOverrideCellInnerContent,
                    overrides: {
                        projectFiles: {
                            linkUrl: isInPageRenderMode()
                                ? `/projects/detail/${row.linkId}`
                                : `/projekt/projekt.aspx#/detail/${row.linkId}`,
                            overrideCellInnerContent: {
                                name: (row, name) => (
                                    <a
                                        href={buildLinkToFile('Projects', row.fileId)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <FileIcon file={row} color="black" /> {name}
                                    </a>
                                ),
                            },
                        },
                        nordProFiles: {
                            linkUrl: `/projektNordschakt/NordPro.aspx?id=${row.linkId}`,
                        },
                        osterlingsProFiles: {
                            linkUrl: `/projektOsterlings/OstPro.aspx?id=${row.linkId}`,
                        },
                        viqmaProFiles: {
                            linkUrl: `/projektViqma/prospekt.aspx?id=${row.linkId}`,
                        },
                        specProFiles: {
                            linkUrl: `/projektSpecial/specPro.aspx?id=${row.linkId}`,
                        },
                    },
                },
                [MODULES.SYSTEMATIC_TASKS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/systematicTasks/detail/${row.linkId}`
                        : `/revisioner/systematiskaUppg.aspx?id=${row.linkId}`,
                    displayRowIfNoUserSet: true,
                    hiddenColumns: ['url'],
                    linkProperty: 'task',
                    overrideCellInnerContent: webFormsHostedFileOverrideCellInnerContent,
                    overrideTitle: 'systematicTasksUploadedFiles',
                },
                [MODULES.IMPROVEMENTS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/improvements/activites/${row.linkId}`
                        : `/forbattring/forbIdee.aspx#/activites/${row.linkId}`,
                    displayRowIfNoUserSet: true,
                    hiddenColumns: ['fileId'],
                    linkProperty: 'activity',
                    overrideCellInnerContent: {
                        name: (row, name) => (
                            <a
                                href={buildLinkToFile('ImprovementActivities', row.fileId)}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FileIcon file={row} color="black" /> {name}
                            </a>
                        ),
                    },
                    overrideTitle: 'improvementsUploadedFiles',
                },
                [MODULES.OBJECTS]: {
                    displayRowIfNoUserSet: true,
                    hiddenColumns: ['url'],
                    overrideCellInnerContent: webFormsHostedFileOverrideCellInnerContent,
                    overrides: {
                        objectFiles: {
                            linkUrl: '/objekt/objekt.aspx',
                            linkProperty: 'objekt',
                        },
                        objectCaseFiles: {
                            linkUrl: `/objekt/arenden.aspx?id=${row.linkId}`,
                            linkProperty: 'case',
                        },
                        objectRiskFiles: {
                            linkUrl: `/objekt/risker.aspx?id=${row.linkId}`,
                            linkProperty: 'risk',
                        },
                        objectRiskActionPlanFiles: {
                            linkUrl: `/objekt/risker.aspx?id=${row.linkId}`,
                            linkProperty: 'risk',
                        },
                        calibrationFiles: {
                            linkUrl: `/objekt/matdon.aspx`,
                            linkProperty: 'measurementDevice',
                        },
                    },
                },
                [MODULES.GOALS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/goals/${row.linkId}`
                        : `/mal/malModul.aspx#/${row.linkId}`,
                    displayRowIfNoUserSet: true,
                    hiddenColumns: ["fileId"],
                    linkProperty: 'goal',
                    overrideCellInnerContent: {
                        name: (row, name) => (
                            <a
                                href={buildLinkToFile('Goals', row.fileId)}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FileIcon file={row} color="black" /> {name}
                            </a>
                        ),
                    },
                    overrideTitle: 'goalUploadedFiles',
                },
                [MODULES.SUPPLIERS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/supplier/${row.linkId}`
                        : `/lev/lev.aspx?id=${row.linkId}`,
                    displayRowIfNoUserSet: true,
                    hiddenColumns: ['url'],
                    linkProperty: 'supplier',
                    overrideCellInnerContent: webFormsHostedFileOverrideCellInnerContent,
                    overrideTitle: 'supplierUploadedFiles',
                },
                [MODULES.CHEMICALS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/chemical/${row.linkId}`
                        : `/kemi/kemi.aspx?id=${row.linkId}&planId=${row.planId}`,
                    displayRowIfNoUserSet: true,
                    hiddenColumns: ['url'],
                    linkProperty: 'product',
                    overrideCellInnerContent: webFormsHostedFileOverrideCellInnerContent,
                    overrideTitle: 'chemicalsUploadedFiles',
                },
            }

            const defaults = {
                hiddenColumns: [],
                userIdField: ['responsibleId'],
                width: '8' as SemanticWIDTHS,
            }

            const info = rowInfo[moduleId]

            if (info?.overrides?.[subTitleKey])
                return { ...defaults, ...info, ...info.overrides[subTitleKey] }
            else return { ...defaults, ...info }
        }
