import React from 'react'
import ModuleMenu from '../../../common/moduleMenu'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IssuesAdminRole, SystemAdminRole, isInRole } from '../../../../auth/roles'
import { getState } from '../../../../state'

const IssueMenu: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const {
        state: { userProfile },
    } = getState()

    /* eslint react/jsx-key: "off" */
    return ModuleMenu({
        all: [<NavLink to={`./`}>{t('issues')}</NavLink>, <NavLink to={`./accidents`}>{t('accidentIncidentRisk')}</NavLink>],
        admin: isInRole(userProfile, IssuesAdminRole) ? [<NavLink to={`./admin/settings`}>{t('moduleSettings')}</NavLink>, <NavLink to={`./admin/statistics`}>Statistik</NavLink>] : undefined,
        systemAdmin: isInRole(userProfile, SystemAdminRole) ? [<NavLink to={`./systemadmin`}>Tilldela admin i modul</NavLink>] : undefined,
    })
}

export default IssueMenu
