import React from "react"
import { Controller, FieldValues, Control } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormDropdown, Popup } from "semantic-ui-react"

export interface IDropdownOption {
    value: string | number | boolean
    text: string
}

interface IControlDropdownProps<U extends IDropdownOption, T extends FieldValues = any> {
    name: string
    control: Control<T>
    collection: U[]
    disabled: boolean
    label: string
    overrideOnChange?: (value: any) => void
    className?: string
    required?: boolean
    popupText?: string
    clearable?: boolean
    multiple?: boolean
}

const ControlDropdown = <U extends IDropdownOption>({
    name,
    control,
    collection,
    disabled,
    label,
    overrideOnChange,
    className,
    required = false,
    popupText = '',
    clearable = false,
    multiple = false
}: IControlDropdownProps<U>) => {
    const { t } = useTranslation()
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Popup
                    content={popupText}
                    disabled={!popupText}
                    trigger={
                        <span>
                            <FormDropdown
                                label={label}
                                fluid
                                required={required}
                                selection
                                value={value}
                                disabled={disabled}
                                options={collection}
                                clearable={clearable}
                                multiple={multiple}
                                search
                                onChange={(e, { value }) => overrideOnChange ? overrideOnChange(value) : onChange(value)}
                                error={!!error && { content: t(`${error.message}`), }}
                                className={className}
                            />
                        </span>
                    }
                />
            )}
        />
    )
}
export default ControlDropdown