import React, { FunctionComponent, useState, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import {
    Button,
    Container,
    Grid,
    Header,
    Message,
    Segment,
} from 'semantic-ui-react'
import Files from '../../../common/files'
import { useBreadcrumbs } from '../../../../hooks/docs/process'
import { Printer, Edit, ArrowLeftCircle, Info } from 'react-feather'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { getState } from '../../../../state'
import { hasModule, MODULES } from '../../../../auth/modules'
import { DocumentsAdminRole, isInRole } from '../../../../auth/roles'
import ReactToPrint from 'react-to-print'
import PrintViewHeader from '../viewPage/printViewHeader'
import DocumentInfo, { DocumentInfoState } from './DocumentInfo'
import LawsTable from '../viewPage/LawConnections/LawsTable'
import DocumentChildrenList from '../viewPage/documentChildrenList'
import DocumentSectionModule from '../viewPage/DocumentSectionModule'
import { RisksTableSectionModule } from '../viewPage/Risks/RisksTable'
import { SystematicTasksTableSectionModule } from '../viewPage/SystematicTasksTable'
import ExternalDocumentsTable from '../viewPage/ExternalDocuments/ExternalDocumentsTable'
import DocumentBreadcrumbs from './DocumentBreadcrumbs'
import { IViqmaDocument } from '../../../../api/documents'
import PrintWrapper from '../../../wrappers/PrintWrapper'


export interface DocumentProps {
    document: IViqmaDocument
    onStateInvalid(): void
}

const Document: FunctionComponent<DocumentProps> = ({ document, onStateInvalid }) => {
    const { t } = useTranslation()
    const { state } = getState()
    const { id, underReferral, isConcernDocument } = document
    const printRef = useRef(null)
    const [documentInfoState, setDocumentInfoState] = useState<DocumentInfoState>('show')
    const bread = useBreadcrumbs(+id)

    const isAdmin = isInRole(state.userProfile, DocumentsAdminRole)

    return (
        <Container>
            <Segment vertical>
                {isConcernDocument && (
                    <Message
                        info
                        icon={
                            <SemanticFeatherIcon FeatherIcon={Info} size="32px" centerIcon={true} />
                        }
                        content={t('documentBelongsTo', { name: state.concern?.name })}
                    />
                )}

                {underReferral && (
                    <>
                        <Header
                            content={t('routineIsOnRemiss')}
                            color="orange"
                            textAlign="center"
                        />
                        <hr style={{ borderColor: '#f2711c' }} />
                    </>
                )}

                <Grid stackable columns="equal">
                    <Grid.Column verticalAlign="middle">
                        <DocumentBreadcrumbs hierarchy={bread?.hierarchy || []} documentId={+id} />
                        <DocumentChildrenList>{bread?.children || []}</DocumentChildrenList>
                    </Grid.Column>
                    <Grid.Column textAlign="right" verticalAlign="middle">
                        <Button.Group>
                            <ReviewActionButton
                                isAdmin={isAdmin}
                                isConcernDocument={isConcernDocument}
                                onClick={state => setDocumentInfoState(state)}
                                underReferral={underReferral}
                                state={documentInfoState}
                            />

                            <ReactToPrint
                                trigger={() => (
                                    <Button
                                        type="button"
                                        icon={<SemanticFeatherIcon FeatherIcon={Printer} />}
                                        content={t('print')}
                                        disabled={documentInfoState !== 'show'}
                                    />
                                )}
                                content={() => printRef.current!}
                                pageStyle="padding: 20px;"
                            />
                        </Button.Group>
                    </Grid.Column>
                </Grid>
            </Segment>

            <div ref={printRef}>
                <PrintWrapper title={document.name} headerContent={
                    <PrintViewHeader document={document} />
                }>
                    <DocumentInfo
                        document={document}
                        infoContainerState={documentInfoState}
                        isAdmin={isAdmin}
                        onStateChanged={state => setDocumentInfoState(state)}
                        onStateInvalid={onStateInvalid}
                    />

                    <DocumentSectionModule title={t('files')} attachTop={isAdmin}>
                        <Files
                            module="Documents"
                            collectionId={id}
                            mayAutoUpload
                            readOnly={!isAdmin}
                            enableDragDrop={documentInfoState !== 'edit'}
                            includeToken={false}
                        />
                    </DocumentSectionModule>

                    {hasModule(state)(MODULES.LAWS) && <LawsTable documentId={+id} isAdmin={isAdmin} />}

                    <ExternalDocumentsTable documentId={id} isAdmin={isAdmin} />

                    {hasModule(state)(MODULES.SYSTEMATIC_TASKS) && (
                        <SystematicTasksTableSectionModule documentId={id} isAdmin={isAdmin} />
                    )}

                    {hasModule(state)(MODULES.RISKS) && (
                        <RisksTableSectionModule connectedId={+id} type="document" />
                    )}
                </PrintWrapper>
            </div>
        </Container>
    )
}

export default Document

const ReviewActionButton = ({
    underReferral,
    state,
    isAdmin,
    isConcernDocument,
    onClick,
}: {
    underReferral: boolean
    state: DocumentInfoState
    isAdmin: boolean
    isConcernDocument: boolean
    onClick(state: DocumentInfoState): void
}): JSX.Element => {
    const { t } = useTranslation()

    if (!isConcernDocument && isAdmin && underReferral && state === 'show')
        return (
            <Button
                type="button"
                icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                content={t('review suggestion')}
                onClick={() => onClick('review')}
            />
        )
    if (state === 'review')
        return (
            <Button
                type="button"
                icon={<SemanticFeatherIcon FeatherIcon={ArrowLeftCircle} />}
                content={t('cancel')}
                onClick={() => onClick('show')}
            />
        )
    if (state === 'edit')
        return (
            <Button
                type="button"
                icon={<SemanticFeatherIcon FeatherIcon={ArrowLeftCircle} />}
                content={t('cancel')}
                onClick={() => onClick('show')}
            />
        )
    else
        return (
            <Button
                type="button"
                icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                content={t('submit suggestion')}
                onClick={() => onClick('edit')}
                disabled={underReferral}
            />
        )
}
