import React from 'react'
import { Routes, Route } from 'react-router'

const SuppliersModule: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route path={'/'} element={<h1>SuppliersModule</h1>} />
        </Routes>
    )
}

export default SuppliersModule
