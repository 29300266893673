import React, { useState } from 'react'
import { Menu, Plus, Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
    Card,
    Grid,
    List,
    ItemDescription,
    Table,
    Button,
    Popup,
    Dropdown,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { formatDate } from '../../actions/util'
import { GoalsAdminRole } from '../../auth/roles'
import { Goal, GoalWithMeasurementInfo } from '../../hooks/goals'
import { useIsInRole } from '../../hooks/role'
import { LineColor } from '../../styles'
import CustomConfirm from '../common/customConfirm'
import DateDisplay from '../common/dateDisplay'
import { DepartmentDisplay, LocationDisplay } from '../common/globalStateDisplay'
import FlexBox from '../common/flexBox'
import IfIsInRole from '../common/ifIsInRole'
import ReminderButton from '../common/reminderButton'
import TextDisplay from '../common/textDisplay'
import UserNameDisplay from '../common/UserNameDisplay'
import GoalProgress from './goalProgress'

export interface GoalCardProps {
    goals: GoalWithMeasurementInfo[]
    hasChildren: boolean
    onAddSubGoal: () => void
    onDelete: (goalId: number) => Promise<unknown>
}

const GoalCardElement = styled(Card)`
    background-color: ${props => (props.$closed ? '#def7ad !important' : undefined)};
`

const CardContentNoGrow = styled(Card.Content)`
    flex-grow: 0 !important;
`

const GoalCard: React.FunctionComponent<GoalCardProps> = ({
    goals,
    hasChildren,
    onAddSubGoal,
    onDelete,
}) => {
    const { t } = useTranslation()
    const { isInRole: isAdmin } = useIsInRole(GoalsAdminRole)
    const [selectedGoal, setSelectedGoal] = useState<GoalWithMeasurementInfo>(goals[0])

    const handleDelete = (goalId: number) =>
        onDelete(goalId).then(() => {
            if (goals.length === 1) return
            const nextGoal = goals.filter(x => x.id !== goalId).at(-1)
            if (nextGoal) setSelectedGoal(nextGoal)
        })

    const renderKpiMeasurementSummary = () => {
        if (!selectedGoal.targetValue || !selectedGoal?.currentValue) return null

        return (
            <CardContentNoGrow>
                <Grid columns={2}>
                    <Grid.Column>
                        <List>
                            <List.Item>
                                <ItemDescription>{t('targetValue')}</ItemDescription>
                                {selectedGoal?.targetValue}
                            </List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column>
                        <List>
                            <List.Item>
                                <ItemDescription>{t('currentValue')}</ItemDescription>
                                {selectedGoal?.currentValue}
                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid>
            </CardContentNoGrow>
        )
    }

    const renderGoalMeasurementSummary = () => {
        if (
            selectedGoal?.initialValue === undefined ||
            selectedGoal?.initialValue === null ||
            selectedGoal?.targetValue === undefined ||
            selectedGoal?.targetValue === null
        )
            return null

        return (
            <CardContentNoGrow>
                <Grid columns={2}>
                    <Grid.Column>
                        <List>
                            <List.Item>
                                <ItemDescription>{t('startValue')}</ItemDescription>
                                {selectedGoal?.initialValue}
                            </List.Item>
                        </List>
                    </Grid.Column>

                    <Grid.Column>
                        <List>
                            {selectedGoal?.currentValue ? (
                                <List.Item>
                                    <ItemDescription>{t('currentValue')}</ItemDescription>
                                    {selectedGoal?.currentValue} {t('of')}{' '}
                                    {selectedGoal?.targetValue} {selectedGoal?.unit}
                                </List.Item>
                            ) : (
                                <List.Item>
                                    <ItemDescription>{t('targetValue')}</ItemDescription>
                                    {selectedGoal?.targetValue} {selectedGoal?.unit}
                                </List.Item>
                            )}
                        </List>
                    </Grid.Column>
                </Grid>
            </CardContentNoGrow>
        )
    }

    return (
        <GoalCardElement $closed={selectedGoal?.status && selectedGoal?.status > 1}>
            {goals.length > 1 && (
                <GoalTabSelector
                    goals={goals}
                    selectedGoal={selectedGoal}
                    onGoalChange={setSelectedGoal}
                />
            )}

            <CardContentNoGrow>
                <FlexBox justifyContent="end">
                    {isAdmin && !selectedGoal.parentId && (
                        <Button
                            icon={<Plus size="18px" />}
                            primary
                            size="tiny"
                            onClick={() => onAddSubGoal()}
                            title={t('newSubGoal')}
                        />
                    )}

                    <ReminderButton
                        template="GoalReminder"
                        trackingSetId={selectedGoal.id}
                        userId={selectedGoal.responsibleId}
                        params={{
                            topic: selectedGoal.title,
                            doneBefore: formatDate(selectedGoal.latestDate),
                        }}
                    />

                    <IfIsInRole role={GoalsAdminRole}>
                        {hasChildren && !selectedGoal.parentId ? (
                            <Popup
                                content={t('cantDeleteGoalWithChildren')}
                                trigger={
                                    <span>
                                        <Button
                                            size="mini"
                                            disabled={true}
                                            icon={<Trash2 size="18px" />}
                                            negative
                                            type="button"
                                        />
                                    </span>
                                }
                            />
                        ) : (
                            <CustomConfirm
                                text={t('sureYouWantToDeleteGoal')}
                                trigger={
                                    <Button
                                        size="mini"
                                        icon={<Trash2 size="18px" />}
                                        negative
                                        type="button"
                                    />
                                }
                                onConfirm={() => handleDelete(selectedGoal.id!)}
                            />
                        )}
                    </IfIsInRole>
                </FlexBox>

                <GoalProgress goal={selectedGoal} />
                <Card.Header>
                    <Link to={`${selectedGoal.id}`}>{selectedGoal.title}</Link>
                </Card.Header>
                <Card.Description>{selectedGoal.description}</Card.Description>
            </CardContentNoGrow>
            <CardContentNoGrow>
                <Grid columns={2}>
                    <Grid.Column>
                        <List>
                            <List.Item>
                                <ItemDescription>{t('responsible')}</ItemDescription>
                                <UserNameDisplay userId={selectedGoal.responsibleId} />
                            </List.Item>
                            <List.Item>
                                <ItemDescription>{t('department')}</ItemDescription>
                                <DepartmentDisplay departmentId={selectedGoal.departmentId} />
                            </List.Item>
                            {!!selectedGoal.locationId && (
                                <List.Item>
                                    <ItemDescription>{t('locationId')}</ItemDescription>
                                    <LocationDisplay locationId={selectedGoal.locationId} />
                                </List.Item>
                            )}
                        </List>
                    </Grid.Column>
                    <Grid.Column>
                        <List>
                            <List.Item>
                                <ItemDescription>{t('endDate')}</ItemDescription>
                                <DateDisplay
                                    date={selectedGoal.latestDate}
                                    checkForPassed={selectedGoal.status === 1}
                                />
                            </List.Item>
                            {selectedGoal.endedDate && (
                                <List.Item>
                                    <ItemDescription>{t('endedDate')}</ItemDescription>
                                    <DateDisplay
                                        date={selectedGoal.endedDate}
                                        checkForPassed={false}
                                    />
                                </List.Item>
                            )}
                            {!!selectedGoal?.numActivities && (
                                <List.Item>
                                    <ItemDescription>{t('numActivities')}</ItemDescription>
                                    {selectedGoal?.numActivities}
                                </List.Item>
                            )}
                        </List>
                    </Grid.Column>
                </Grid>
            </CardContentNoGrow>
            <Card.Content>
                <Table compact="very" basic="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{t('decision')}</Table.HeaderCell>
                            <Table.HeaderCell collapsing>{t('occasion')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('date')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {selectedGoal.decisions?.slice(0, 1).map((x, i) => (
                            <Table.Row key={x.id}>
                                <Table.Cell>
                                    <TextDisplay maxLength={i === 0 ? 400 : 50} text={x.decision} />
                                </Table.Cell>
                                <Table.Cell>{x.opportunity}</Table.Cell>
                                <Table.Cell>{formatDate(x.date)}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Card.Content>
            {selectedGoal.isKpi ? renderKpiMeasurementSummary() : renderGoalMeasurementSummary()}
        </GoalCardElement>
    )
}

const TabContainer = styled.div`
    display: flex;
    gap: 5px;
    position: absolute;
    top: -14px;
    left: 20px;
`

const getHandleBackgroundColor = (selected: boolean, closed: boolean) => {
    if (!closed) return selected ? 'rgb(255,255,255)' : 'rgba(240,240,240)'
    return selected ? `#def7ad` : '#afc486'
}

const TabHandle = styled.div<{ $selected: boolean; $closed: boolean }>`
    height: 15px;
    width: 35px;
    cursor: pointer;

    background-color: ${props => getHandleBackgroundColor(props.$selected, props.$closed)};
    border-top: 1px solid rgba(0, 0, 0, 0.37);
    border-left: 1px solid rgba(0, 0, 0, 0.37);
    border-right: 1px solid rgba(0, 0, 0, 0.37);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-align: center;
`

const BorderedDropdownMenu = styled(Dropdown.Menu)`
    border: 1px solid ${LineColor} !important;
`

const StyledMenuItem = styled(Dropdown.Item)`
    background-color: ${props => getHandleBackgroundColor(props.$selected, props.$closed)};
`

interface GoalTabSelectorProps {
    goals: Goal[]
    selectedGoal: Goal
    onGoalChange: (goal: Goal) => void
}

const GoalTabSelector: React.FunctionComponent<GoalTabSelectorProps> = ({
    goals,
    selectedGoal,
    onGoalChange,
}) => {
    const tabGoals = goals.slice(0, 6)
    const leftOver = goals.slice(6)

    return (
        <TabContainer>
            {tabGoals.map(g => (
                <Popup
                    key={g.id}
                    size={'small'}
                    content={g.title}
                    trigger={
                        <TabHandle
                            $selected={g.id === selectedGoal.id}
                            $closed={!!g.status && g.status > 1}
                            onClick={() => onGoalChange(g)}
                        />
                    }
                />
            ))}

            {!!leftOver.length && (
                <div style={{ position: 'relative', zIndex: '10' }}>
                    <Dropdown
                        trigger={
                            <TabHandle $selected={false} $closed={false}>
                                <Menu size={'14px'} />
                            </TabHandle>
                        }
                        icon={null}
                    >
                        <BorderedDropdownMenu scrolling>
                            {leftOver.map(g => (
                                <StyledMenuItem
                                    key={g.id}
                                    onClick={() => onGoalChange(g)}
                                    $selected={g.id === selectedGoal.id}
                                    $closed={!!g.status && g.status > 1}
                                >
                                    {g.title}
                                </StyledMenuItem>
                            ))}
                        </BorderedDropdownMenu>
                    </Dropdown>
                </div>
            )}
        </TabContainer>
    )
}

export default GoalCard
